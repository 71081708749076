// Body
$body-bg: #f8fafc;
$body-home: #0e0e0e;
$body-fondo: #0f0f0f;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$grey: #cccccc;
$grey_light: #eeeeee;

// Messages
$warning: #ffdf1a;
$error: #ff0000;

$white: #ffffff;
$principal: #68b9fd;
$subpr: #bae0ff;
$black: #000000;
$gris: #595959;
