// Variables
@import '_variables';


/* funcionamiento básico del sistema de pestañas */
.tab-content {
    display: none;
}
  
.tab:target .tab-content, .tab:last-of-type .tab-content {
    display: block;
}
  
.tab:target ~ .tab:last-of-type .tab-content {
    display: none;
}

  /* parámetros para configurar las pestañas */
:root {
    --tabs-border-size: 1px;
    --tabs-width: 60px;
    --tabs-height: 30px;
}
  
  /* aspecto básico */
.tabs * {
    box-sizing: border-box;
}

  /* esto es para posicionar las pestañas correctamente */
.tab-container {
    position: relative;
    padding-top: var(--tabs-height); /* en esta zona colocaremos las pestañas */
} 
#tab1>a { --tabs-position: 0; }
#tab2>a { --tabs-position: 1; }
#tab3>a { --tabs-position: 2; }
#tab4>a { --tabs-position: 3; }
#tab5>a { --tabs-position: 4; }
#tab6>a { --tabs-position: 5; }
#tab7>a { --tabs-position: 6; }
#tab8>a { --tabs-position: 7; }
#tab9>a { --tabs-position: 8; }
.tab>a {
    text-align: center;
    position: absolute;
    width: calc(var(--tabs-width));
    height: calc(var(--tabs-height) + var(--tabs-border-size)); 
    top: 0;
    left: calc(var(--tabs-width) * var(--tabs-position)); /* posición de cada pestaña */
}

/* más aspecto */
.tabs { 
    padding: 0px;
    color: $black;
}
.tab-content {
    background-color: $white;
    padding: 0px;
    border: var(--tabs-border-size) solid $principal;
    border-radius: 0 0 0px 0px;
    position: relative;
    z-index: 100;
}
.tab>a {
    background-color: $white; 
    padding: 5px;
    border: var(--tabs-border-size) solid $grey;
    border-radius: 10px 10px 0 0;
    border-bottom: 0;
    color: $grey;
    text-decoration: none;
}
.tab>a:hover {
    border: var(--tabs-border-size) solid $principal;
    color: $principal;
    cursor: pointer;
    text-decoration: none;
}
.tab:target>a, .tab:last-of-type>a {
    background-color: $white; 
    border: var(--tabs-border-size) solid $principal;
    color: $principal;
    border-bottom: 0px;
    z-index: 200;
}
.tab:target ~ .tab:last-of-type>a {
    background-color: $white; 
    color: $grey;
    border: var(--tabs-border-size) solid $grey;
    z-index: 0; 
}
.tab:target ~ .tab:last-of-type>a:hover {
    color: $principal;
    border: var(--tabs-border-size) solid $principal;
    text-decoration: none;
}